<template>
  <div>
    <a
      v-for="feature in features"
      :key="feature.name"
      :href="feature.link"
      class="mb-2 white--text text-foot d-block text-none"
      v-html="feature.name"
    />
  </div>
</template>

<script>
export default {
  name: "SectionFeaturesAlt",
  data: () => ({
    features: [
      { name: "Visi Misi", link: "/tentang-kami" },
      { name: "Struktur dan Manajemen", link: "/struktur-perusahaan" },
      { name: "Mekanisme Penjaminan", link: "/mekanisme-penjaminan" },
    ],
  }),
};
</script>

<style scoped>
.text-foot {
  margin-bottom: 30px;
  color: #fff;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.02em;
  display: inline-block;
  font-weight: 300;
}
</style>
