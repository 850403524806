import { render, staticRenderFns } from "./InfoFeatures.vue?vue&type=template&id=7d8b025f&scoped=true&"
import script from "./InfoFeatures.vue?vue&type=script&lang=js&"
export * from "./InfoFeatures.vue?vue&type=script&lang=js&"
import style0 from "./InfoFeatures.vue?vue&type=style&index=0&id=7d8b025f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d8b025f",
  null
  
)

export default component.exports